import React, {useEffect, useState} from "react";
import Button from '../../components/button'
import {Link, useParams} from 'react-router-dom'
import FaceDetection from '../../components/face-detection';
import LoadingSpinner from '../../components/loading-spinner';
import {updatePhoto, clear_employee, UPDATE_PHOTO, validate_link} from '../../redux/employees'
import {useDispatch, useSelector} from 'react-redux';
import {ToastContainer, toast} from 'react-toastify';
import {useHistory} from 'react-router-dom';
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import {IconButton} from "@material-ui/core";
import PhotoCameraRoundedIcon from "@material-ui/icons/PhotoCameraRounded";
import './fileMobile.css';


const UploadPhoto = () => {
    let history = useHistory();
    let dispatch = useDispatch();

    const _update_photo = useSelector(
        (state) => state.employees_module._update_photo
    );

    const {id, code} = useParams();

    const [file, setFile] = useState(null);

    const [fileImage, setFileImage] = useState(null);

    const [error, setError] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        validate_link(id, code).then(res => {
            if (res.status !== 200) {
                // setError(true)
            } else {
                setError(false)
            }
        }).catch(err => {
            // setError(true)
        })

    }, [id, code, history])


    useEffect(() => {
        if (_update_photo.status === 200) {
            toast.success("Imagen Actualizada con exito")
            dispatch(clear_employee(UPDATE_PHOTO))
            setIsLoading(false);
            history.push("/confirmar/foto/");
        } else if (_update_photo.status !== 0) {
            setIsLoading(false);
            toast.error(_update_photo.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            dispatch(clear_employee(UPDATE_PHOTO));
        }
    }, [_update_photo, dispatch, history])


    useEffect(() => {

        //add in bodytag vertical-layout vertical-menu-modern semi-dark-layout 1-column  navbar-sticky footer-static bg-full-screen-image  blank-page blank-page" data-open="click" data-menu="vertical-menu-modern" data-col="1-column" data-layout="semi-dark-layout"
        document.body.className = ""
        //remove in bodytag vertical-layout vertical-menu-modern semi-dark-layout 1-column  navbar-sticky footer-static bg-full-screen-image  blank-page blank-page" data-open="click" data-menu="vertical-menu-modern" data-col="1-column" data-layout="semi-dark-layout"

    }, [])

    const comprimirImagen = (imagenComoArchivo, porcentajeCalidad) => {
        return new Promise((resolve, reject) => {
            const $canvas = document.createElement("canvas");
            const imagen = new Image();
            imagen.onload = () => {
                $canvas.width = imagen.width;
                $canvas.height = imagen.height;
                $canvas.getContext("2d").drawImage(imagen, 0, 0);
                $canvas.toBlob(
                    (blob) => {
                        if (blob === null) {
                            return reject(blob);
                        } else {
                            resolve(blob);
                        }
                    },
                    "image/jpeg",
                    porcentajeCalidad / 100,
                );
            };
            imagen.src = URL.createObjectURL(imagenComoArchivo);
        });
    };

    const handleFile = (blob) => {
        blob.lastModifiedDate = new Date();
        //random name
        blob.name = `${new Date().getTime()}.jpg`;
        setFile(blob);

    }


    const handleSubmit = async () => {
        const blob = await comprimirImagen(file, 40);
        setIsLoading(true);
        let form = new FormData();
        var fileConvert = new File([blob], file.name);
        form.append("foto", fileConvert);
        dispatch(updatePhoto(id, code, form));
    }

    const handleError = (error) => {
        toast.error(error, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    const handleCapture = (target) => {
        if (target.files) {
            if (target.files.length !== 0) {
                const file = target.files[0];
                setFile(file);
                const newUrl = URL.createObjectURL(file);
                setFileImage(newUrl);
            }
        }
    };

    function handleClear() {
        setFileImage(null);
        setFile(null);
    }

    if (error) {
        //link caducado o no valido
        return (
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header">
                                <h4 className="card-title">Link caducado o no valido</h4>
                            </div>
                            <div className="card-content">
                                <div className="card-body">
                                    <p>El link que intenta acceder no es valido o ya caduco</p>
                                </div>
                            </div>
                            <Link to="/" className="btn btn-primary">Volver al inicio</Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    } else {
        const web = navigator.userAgent.toUpperCase();
        let mobile = web.indexOf("MOBILE");
        let android = web.indexOf("ANDROID");
        let iphone = web.indexOf("IPHONE");
        // console.log(web);
        return (
            <div className="app-content">
                {isLoading && <LoadingSpinner/>}

                <ToastContainer/>
                {mobile === -1 && android === -1 && iphone === -1 ? (
                    <FaceDetection
                        open={true}
                        handleFile={handleFile}
                        handleError={handleError}
                        handleSubmit={handleSubmit}
                    />
                ) : (
                    <div className="root">
                        <Grid container>
                            <Grid item xs={12}>
                                <div className={!file && 'btnPhoto'}>
                                    <h5>Presione el botón para tomar la fotografía</h5>
                                    {fileImage && (
                                        <>
                                            <Box
                                                display="flex"
                                                justifyContent="center"
                                                border={1}
                                                className="imgBox"
                                            >
                                                <img src={fileImage} alt={"snap"} className="img" width="300"
                                                     height="300"/>
                                            </Box>
                                        </>
                                    )}
                                    <input
                                        accept="image/*"
                                        className="input"
                                        id="icon-button-file"
                                        type="file"
                                        capture="environment"
                                        onChange={(e) => handleCapture(e.target)}
                                    />
                                    <label htmlFor="icon-button-file">
                                        <IconButton
                                            color="primary"
                                            aria-label="Tomar fotografía"
                                            component="span"
                                        >
                                            <PhotoCameraRoundedIcon fontSize="large" color="primary"/>
                                        </IconButton>
                                    </label>
                                    {
                                        fileImage ?
                                            <div className="text-center">
                                                <Button
                                                    text="Guardar"
                                                    theme="success"
                                                    type="submit"
                                                    classes="m-1"
                                                    onClick={handleSubmit}
                                                />
                                                <Button
                                                    text="Cancelar"
                                                    theme="danger"
                                                    type="submit"
                                                    classes="m-1"
                                                    onClick={handleClear}
                                                />
                                            </div> : null
                                    }
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                )}
            </div>
        )
    }
}

export default UploadPhoto;
