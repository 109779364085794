import React, { useState, useEffect } from 'react';
import Button from "../../../components/button";
import Breadcrumbs from "../../../components/breadcrumbs";
import Input from "../../../components/input";
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useHistory,useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import MultiselectTwoSides from 'react-multiselect-two-sides';
import "./create.scss";
import {
    update_group,
    UPDATE_GROUP,
    clear_user_module,
    get_all_groups
} from '../../../redux/users'
import SelectInput from "../../../components/select";
import Select from "react-select";
import LoadingSpinner from "../../../components/loading-spinner";


const UpdateGroup = () => {
    const { id } = useParams();
    const { token } = useSelector((state) => state.users_module.login.data)||null;

    const [isLoading, setIsLoading] = useState(false);

    const dispatch = useDispatch();
    const history = useHistory();
    const _get_all_permissions = useSelector((state) => state.users_module._get_all_permissions);
    const _get_all_jobs = useSelector(
        (state) => state.jobs_module._get_all_jobs
    );
    const _get_all_groups = useSelector((state) => state.users_module._get_all_groups);
    const _update_group = useSelector((state) => state.users_module._update_group);

    const [permission, setPermission] = useState([

    ]);
    const [permissionSelect, setPermissionSelect] = useState([]);

    const [form, setForm] = useState({
        nombre: "",
        permisos: [],
        tipo: '',
        nombre_tipo: '',
        cargos: [],
    });

    const [jobs, setJobs] = useState([]);
    const [all_jobs, setAllJobs] = useState([]);
    const [job_select, setJobSelect] = useState([]);

    let type_options = [
        {'id': 'all', 'nombre': 'Todos'},
        {'id': 'employee', 'nombre': 'Empleado'},
        {'id': 'supplier', 'nombre': 'Proveedor'},
        {'id': 'vivitant', 'nombre': 'Visitate'},
    ]

    useEffect(() => {
        if (_get_all_jobs && _get_all_jobs.data && _get_all_jobs.data.datos) {
            let all_value = _get_all_jobs.data.datos.filter(x => x.activo === true);
            setAllJobs(all_value)
            // eslint-disable-next-line
            let options = _get_all_jobs.data.datos.map((item) => {
                if (item.activo === true) {
                    return {
                        value: item.id,
                        label: item.nombre,
                    };
                }
            });
            options.unshift({
                value: "ALL",
                label: "TODOS",
            })
            setJobs(options);
        }
    }, [_get_all_jobs]);

    useEffect(() => {
        if(_get_all_groups.data){
            // eslint-disable-next-line
            _get_all_groups.data.datos.map((item) => {
                if (item.id === parseInt(id)) {
                    let permisos = [];
                    let permisoSelect = [];
                    // eslint-disable-next-line
                    item.permisos.map((it) => {
                        permisos.push({codigo: it.codigo});
                        permisoSelect.push(it.codigo);
                    })
                    // eslint-disable-next-line
                    let name_type = type_options.filter(x => x.id === item.tipo)
                    let n_type = '';
                    if (name_type.length > 0) {
                        n_type = name_type[0].nombre
                    }
                    setForm({nombre: item.nombre, permisos: permisos, tipo: item.tipo, nombre_tipo: n_type});
                    setPermissionSelect(permisoSelect);
                    let cargos = item.cargos.map((v) => {
                        return {
                            value: v.id,
                            label: v.nombre,
                        };
                    })
                    setJobSelect(cargos);
                }
            });
        }
        // eslint-disable-next-line
    }, [_get_all_groups,id]);

    useEffect(() => {
        if (_update_group.status === 200) {
            toast.success("Grupo actualizado correctamente");
            dispatch(clear_user_module(UPDATE_GROUP));
            dispatch(get_all_groups(token));
            history.push("/roles");
            setIsLoading(false);
        } else if (_update_group.status !== 0) {
            dispatch(clear_user_module(UPDATE_GROUP));
            toast.error(_update_group.message);
            setIsLoading(false);
        }
    }, [_update_group, dispatch, history, token]);

    useEffect(() => {
        if (_get_all_permissions.data) {
            if (_get_all_permissions.data.datos !== undefined) {
                let datos = [];
                // eslint-disable-next-line
                _get_all_permissions.data.datos.map((item) => {
                    datos.push({
                        name: item.nombre,
                        value: item.codigo,
                    })
                })
                setPermission(datos);
            }
        }
    }, [_get_all_permissions]);

    const handleChangeMultiselect = (e) => {
        setPermissionSelect(e);
        let permisos = [];
        // eslint-disable-next-line
        e.map((item) => {
            permisos.push({codigo: item});
        })
        setForm({
            ...form,
            permisos: permisos,
        });
    }

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        });
    }

    const handleChangeJob = (e) => {
        let all = e.filter(x => x.value === "ALL")
        if (all.length > 0) {
            setJobSelect(all)
        } else {
            setJobSelect(e);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);
        if (form.nombre === "") {
            toast.error("El nombre del grupo es requerido");
            setIsLoading(false);
            return;
        }
        if (form.tipo === "") {
            toast.error("Debe seleccionar un tipo");
            setIsLoading(false);
            return;
        } else {
            if (form.tipo === 'employee' || form.tipo === 'employee') {
                if (job_select.length === 0) {
                    toast.error("Debe seleccionar por lo menos un cargo");
                    setIsLoading(false);
                    return;
                }
            }
        }
        if (form.permisos.length === 0) {
            toast.error("Debe seleccionar al menos un permiso");
            setIsLoading(false);
            return;
        }

        if (form.tipo === 'employee' || form.tipo === 'all') {
            let all = job_select.filter(x => x.value === "ALL")
            if (all.length > 0) {
                form.cargos = all_jobs;
            } else {
                // eslint-disable-next-line
                let array = job_select.map((item) => {
                    return {
                        id: item.value,
                        nombre: item.label,
                    };
                });
                form.cargos = array;
            }
        } else {
            form.cargos = [];
        }

        dispatch(update_group(form, id, token));
    }

    return (
        <div className="app-content content">
            <ToastContainer/>
            {isLoading && <LoadingSpinner/>}
            <div className="content-overlay"/>

            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-12 mb-2 mt-1">
                        <Breadcrumbs
                            title="Roles"
                            items={[
                                { label: "Roles", link: "/roles" },
                            ]}
                        />
                    </div>
                </div>
                <div className="content-body">
                    <div className="row">
                        <div className="col-12">
                            <form className="card" onSubmit={handleSubmit}>
                                <div className="card-header">
                                    <h4 className="card-title">Crear Rol</h4>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <Input
                                                label="nombre"
                                                name="nombre"
                                                onChange={handleChange}
                                                value={form.nombre}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Tipo</label>
                                                <SelectInput
                                                    label="Tipo"
                                                    name="tipo"
                                                    select={form.nombre_tipo}
                                                    onChange={handleChange}
                                                    options={type_options ? type_options.map((va) => ({
                                                        name: va.nombre,
                                                        id: va.id,
                                                    })) : []}
                                                />
                                            </div>
                                        </div>
                                        {(form.tipo === 'employee' || form.tipo === 'all') &&
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>Cargos</label>
                                                    <Select
                                                        options={jobs}
                                                        isMulti={true}
                                                        onChange={handleChangeJob}
                                                        value={job_select}
                                                    />
                                                </div>
                                            </div>
                                        }
                                        <div className="col-md-12 mt-2">
                                            <MultiselectTwoSides
                                                options={permission}
                                                value={permissionSelect}
                                                className="msts_theme_example"
                                                onChange={handleChangeMultiselect}
                                                availableHeader="Permisos Disponibles"
                                                selectedHeader="Permisos Seleccionados"
                                                labelKey="name"
                                                showControls
                                                searchable
                                            />
                                        </div>
                                    </div>

                                </div>
                                <div className="card-footer d-flex justify-content-end">
                                    <Link to="/roles" className="btn btn-danger m-1">
                                        Cancelar
                                    </Link>
                                    <Button
                                        text="Guardar"
                                        theme="success"
                                        type="submit"
                                        classes="m-1"
                                        disabled={isLoading}
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default UpdateGroup;
