import VisitasAdmin from "./admin/visits/index";
import List from "../pages/admin/visits/list";
import Companies from "../pages/admin/company/index";
import CreateCompany from "../pages/admin/company/create";
import UpdateCompany from "../pages/admin/company/update";
import Costs_Centers from "../pages/admin/cost_center/index";
import CreateCostCenter from "../pages/admin/cost_center/create";
import UpdateCostCenter from "../pages/admin/cost_center/update";
import Employees from "../pages/admin/employee/index";
import CreateEmployee from "../pages/admin/employee/create";
import Import from '../pages/admin/employee/import';
import UpdateEmployee from "../pages/admin/employee/update";
import Supplier from "../pages/admin/supplier/index";
import UpdateSupplier from '../pages/admin/supplier/update';
import CreateSupplier from "../pages/admin/supplier/create";
import Visitant from "../pages/admin/visitant/index";
import UpdateVisitant from "../pages/admin/visitant/update";
import CreateVisitant from "../pages/admin/visitant/create";
import Users from "../pages/admin/users/index";
import CreateUser from "../pages/admin/users/create";
import UpdateUser from "../pages/admin/users/update";
import Jobs from '../pages/admin/job/index';
import CreateJob from '../pages/admin/job/create';
import VisitDetails from '../pages/admin/visits/details';
import UpdateJob from '../pages/admin/job/update';
import GroupList from '../pages/admin/groups/index';
import CreateGroup from '../pages/admin/groups/create';
import UpdateGroup from '../pages/admin/groups/update';
import EmailZonesList from '../pages/admin/email_zones/index';
import UpdateEmailZone from '../pages/admin/email_zones/update';
import Zones from "../pages/admin/zones/index";
import CreateZone from "../pages/admin/zones/create";
import UpdateZone from "../pages/admin/zones/update";
import Visitors from "../pages/admin/employee/visitors";

const routes = [
    {
        path: "/",
        name: "visitas",
        exact: true,
        component: VisitasAdmin,
        show_in_menu: true,
        icon: "bx bx-home-alt",
        meta: {
            requiresAuth: true,
            title: "Visitas",
            permission_required: [
                "add_visits",
                'can_approve_visit'
            ],
        },
    },
    {
        path: "/",
        name: "visitas",
        exact: true,
        component: List,
        show_in_menu: true,
        icon: "bx bx-home-alt",
        meta: {
            requiresAuth: true,
            title: "Visitas",
            permission_required: [
                'can_add_no_scheduled_visit',
            ],
        },
    },
    {
        path: "/visitas/:id",
        name: "visitas",
        exact: true,
        component: VisitDetails,
        show_in_menu: false,
        icon: "bx bx-home-alt",
        meta: {
            requiresAuth: true,
            title: "Visitas",
            permission_required: [
                "view_visits",
            ],
        },
    },
    {
        path: "/empresas",
        name: "empresas",
        exact: true,
        component: Companies,
        icon: "bx bxs-city",
        show_in_menu: true,
        meta: {
            requiresAuth: true,
            title: "Empresas",
            permission_required: [
                "view_company",
            ],
        },
    },
    {
        path: "/empresas/crear",
        name: "empresas",
        exact: true,
        component: CreateCompany,
        icon: "bx bxs-city",
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Empresas",
            permission_required: [
                "add_company",
            ],
        },
    },
    {
        path: "/empresas/editar/:id",
        name: "empresas",
        exact: true,
        component: UpdateCompany,
        icon: "bx bxs-city",
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Empresas",
            permission_required: [
                "change_company",
            ],
        },
    },
    {
        path: "/zonas-correos",
        name: "correos",
        exact: true,
        component: EmailZonesList,
        icon: "bx bx-mail-send",
        show_in_menu: true,
        meta: {
            requiresAuth: true,
            title: "Zonas - Correos",
            permission_required: [
                "view_email",
            ],
        },
    },
    {
        path: "/zona-correo/editar/:id",
        name: "correos",
        exact: true,
        component: UpdateEmailZone,
        icon: "bx bx-mail-send",
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Zonas - Correos",
            permission_required: [
                "change_email",
            ],
        },
    },
    {
        path: "/zonas",
        name: "zonas",
        exact: true,
        component: Zones,
        icon: "bx bx-map-pin",
        show_in_menu: true,
        meta: {
            requiresAuth: true,
            title: "Zonas",
            permission_required: [
                "view_zones",
            ],
        },
    },
    {
        path: "/zona/crear",
        name: "zonas",
        exact: true,
        component: CreateZone,
        icon: "bx bx-map-pin",
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Zonas",
            permission_required: [
                "add_zones",
            ],
        },
    },
    {
        path: "/zona/editar/:id",
        name: "zonas",
        exact: true,
        component: UpdateZone,
        icon: "bx bx-map-pin",
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Zonas",
            permission_required: [
                "change_zones",
            ],
        },
    },
    {
        path: "/cargos",
        name: "cargos",
        exact: true,
        component: Jobs,
        icon: "bx bxs-briefcase",
        show_in_menu: true,
        meta: {
            requiresAuth: true,
            title: "Cargos",
            permission_required: [
                "view_job",
            ],
        },
    },
    {
        path: "/cargos/crear",
        name: "cargos",
        exact: true,
        component: CreateJob,
        icon: "bx bxs-briefcase",
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Cargos",
            permission_required: [
                "add_job",
            ],
        },
    },
    {
        path: "/cargos/editar/:id",
        name: "cargos",
        exact: true,
        component: UpdateJob,
        icon: "bx bxs-briefcase",
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Cargos",
            permission_required: [
                "change_job",
            ],
        },
    },
    {
        path: "/centros-de-costos",
        name: "tiendas",
        exact: true,
        component: Costs_Centers,
        icon: "bx bxs-store-alt",
        show_in_menu: true,
        meta: {
            requiresAuth: true,
            title: "Centros de costo",
            permission_required: [
                "view_costcenter",
            ],
        },
    },
    {
        path: "/centros-de-costos/crear",
        name: "tiendas",
        exact: false,
        component: CreateCostCenter,
        icon: "bx bxs-store-alt",
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Centros de costo",
            permission_required: [
                "add_costcenter",
            ],
        },
    },
    {
        path: "/centros-de-costos/editar/:id",
        name: "tiendas",
        exact: true,
        component: UpdateCostCenter,
        icon: "bx bxs-store-alt",
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Centros de costo",
            permission_required: [
                "change_costcenter",
            ],
        },
    },
    {
        path: "/empleados",
        name: "empleados",
        exact: true,
        icon: "bx bxs-group",
        component: Employees,
        show_in_menu: true,
        meta: {
            requiresAuth: true,
            title: "Empleados",
            permission_required: [
                "view_employees",
            ],
        },
    },
    {
        path: "/empleados/crear",
        name: "empleados",
        exact: false,
        icon: "bx bxs-group",
        component: CreateEmployee,
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Empleados",
            permission_required: [
                "add_employees",
            ],
        },
    },
    {
        path: "/empleados/importar",
        name: "empleados",
        exact: false,
        icon: "bx bxs-group",
        component: Import,
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Empleados",
            permission_required: [
                "add_employees",
            ],
        },
    },
    {
        path: "/empleados/editar/:id",
        name: "empleados",
        exact: true,
        icon: "bx bxs-group",
        component: UpdateEmployee,
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Empleados",
            permission_required: [
                "change_employees",
            ],
        },
    },
    {
        path: "/proveedores",
        name: "proveedores",
        exact: true,
        icon: "bx bxs-truck",
        component: Supplier,
        show_in_menu: true,
        meta: {
            requiresAuth: true,
            title: "Proveedores",
            permission_required: [
                "view_supplier",
            ],
        },
    },
    {
        path: "/proveedores/crear",
        name: "proveedores",
        exact: false,
        icon: "bx bxs-truck",
        component: CreateSupplier,
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Proveedores",
            permission_required: [
                "add_supplier",
            ],
        },
    },
    {
        path: "/proveedores/editar/:id",
        name: "proveedores",
        exact: true,
        icon: "bx bxs-truck",
        component: UpdateSupplier,
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Proveedores",
            permission_required: [
                "change_supplier",
            ],
        },
    },
    {
        path: "/invitados",
        name: "invitados",
        exact: true,
        icon: "bx bxs-user-detail",
        component: Visitant,
        show_in_menu: true,
        meta: {
            requiresAuth: true,
            title: "Invitados",
            permission_required: [
                "view_visitant",
            ],
        },
    },
    {
        path: "/invitados/crear",
        name: "invitados",
        exact: false,
        icon: "bx bxs-user-detail",
        component: CreateVisitant,
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Invitados",
            permission_required: [
                "add_visitant",
            ],
        },
    },
    {
        path: "/invitados/editar/:id",
        name: "invitados",
        exact: true,
        icon: "bx bxs-user-detail",
        component: UpdateVisitant,
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Invitados",
            permission_required: [
                "change_visitant",
            ],
        },
    },
    {
        path: "/visitantes",
        name: "visitantes",
        exact: true,
        icon: "bx bxs-user-rectangle",
        component: Visitors,
        show_in_menu: true,
        meta: {
            requiresAuth: true,
            title: "Visitantes",
            permission_required: [
                "view_visitors",
            ],
        },
    },
    {
        path: "/roles",
        name: "roles",
        icon: "bx bxs-user-pin",
        exact: true,
        component: GroupList,
        show_in_menu: true,
        meta: {
            requiresAuth: true,
            title: "Roles",
            permission_required: [
                "view_group",
            ],
        },
    },
    {
        path: "/roles/crear",
        name: "roles",
        icon: "bx bxs-user",
        exact: true,
        component: CreateGroup,
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Roles",
            permission_required: [
                "add_group",
            ],
        },
    },
    {
        path: "/roles/editar/:id",
        name: "roles",
        icon: "bx bxs-user",
        exact: true,
        component: UpdateGroup,
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Roles",
            permission_required: [
                "change_group",
            ],
        },
    },
    {
        path: "/usuarios",
        name: "usuarios",
        icon: "bx bxs-user",
        exact: true,
        component: Users,
        show_in_menu: true,
        meta: {
            requiresAuth: true,
            title: "Usuarios",
            permission_required: [
                "view_user",
            ],
        },
    },
    {
        path: "/usuarios/crear",
        name: "usuarios",
        icon: "bx bxs-user",
        exact: true,
        component: CreateUser,
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Usuarios",
            permission_required: [
                "add_user",
            ],
        },
    },
    {
        path: "/usuarios/editar/:id",
        name: "usuarios",
        icon: "bx bxs-user",
        exact: true,
        component: UpdateUser,
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Usuarios",
            permission_required: [
                "change_user",
            ],
        },
    },
    /*{
      path: "/ayuda/",
      name: "ayuda",
      icon: "bx bxs-info-circle",
      exact: true,
      component: Help,
      show_in_menu: true,
      meta: {
        requiresAuth: false,
        title: "Ayuda",
        permission_required: [
          "change_user",
        ],
      },
    },
    {
      path: "/detalle/:id",
      name: "ayuda",
      icon: "bx bxs-info-circle",
      exact: true,
      component: HelpDetail,
      show_in_menu: false,
      meta: {
        requiresAuth: false,
        title: "Ayuda",
        permission_required: [
          "change_user",
        ],
      },
    }*/
];

export default routes;
