import React, {useEffect, useState} from "react";
import Button from "../../../components/button";
import Breadcrumbs from "../../../components/breadcrumbs";
import Input from "../../../components/input";
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from "../../../components/select";
import {useDispatch, useSelector} from "react-redux";
import {Link, useHistory, useParams} from "react-router-dom";
import {
    update_zones,
    clear_zone,
    UPDATE_ZONES_SUCCESS,
    get_all_zones,
} from "../../../redux/zones";
import CheckBox from "../../../components/check-box";
import LoadingSpinner from "../../../components/loading-spinner";

/** @module  Pages/CostCenter/UpdateZones */

/**
 * @class UpdateZones - Componente que renderiza la vista de actualizar zonas
 * @extends React.Component
 * @requires react-router-dom
 * @requires react-redux
 * @requires redux
 * @requires pages/admin/zones/update.js
 * @requires components/breadcrumbs
 * @requires components/button
 * @requires components/input
 * @requires components/alert
 * @requires components/select
 * @requires components/link
 * @requires redux/zone
 */

const UpdateZones = () => {
    const history = useHistory();
    const {id} = useParams();
    const dispatch = useDispatch();

    const _zone = useSelector(
        (state) => state.zone_module._get_all_zones
    );

    const {token} = useSelector((state) => state.users_module.login.data) || null;

    const _update_zones = useSelector(
        (state) => state.zone_module._update_zones
    );

    const _users = useSelector(
        (state) => state.users_module._get_all_users
    );

    const [isLoading, setIsLoading] = useState(false);

    const [form, setForm] = useState({
        nombre: "",
        id_usuario_jefe_zona: 0,
        usuario_jefe_zona: "",
        activo: true
    });

    const [users, setUsers] = useState([]);


    useEffect(() => {
        if (_update_zones.status === 200) {
            dispatch(clear_zone(UPDATE_ZONES_SUCCESS));
            dispatch(get_all_zones(token));
            setIsLoading(false);
            history.push("/zonas");
        } else {
            if (_update_zones.status !== 0) {
                toast.error(_update_zones.message);
                dispatch(clear_zone(UPDATE_ZONES_SUCCESS));
                setIsLoading(false);
            }
        }
    }, [_update_zones, dispatch, history, token]);


    useEffect(() => {
        if (_users.data) {
            setUsers(_users.data);
        }
    }, [_users]);


    useEffect(() => {
        if (_zone.data) {
            // eslint-disable-next-line
            _zone.data.datos.map((item) => {
                if (item.id === parseInt(id)) {
                    setForm({
                        ...form,
                        nombre: item.nombre,
                        activo: item.activo,
                        usuario_jefe_zona: item.usuario_jefe_zonal.nombres,
                    });
                }
            });
        }
        // eslint-disable-next-line
    }, [_zone, id]);

    useEffect(() => {
        if (users.length > 0) {
            // eslint-disable-next-line
            users.map((item) => {
                // eslint-disable-next-line
                if ((item.nombres + ' ' + item.apellidos) === form.usuario_jefe_zona) {
                    setForm({
                        ...form,
                        id_usuario_jefe_zona: item.id,
                    });

                }
            })
        }
        // eslint-disable-next-line
    }, [users]);


    const handleChange = (e) => {
        if (e.target.name === "id_usuario_jefe_zona") {
            setForm({
                ...form,
                [e.target.name]: parseInt(e.target.value),
            });
        }  else if (e.target.name === 'activo') {
            setForm({
                ...form,
                [e.target.name]: e.target.checked,
            });
        } else {
            setForm({
                ...form,
                [e.target.name]: e.target.value,
            });
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);
        if (form.nombre === "") {
            toast.error("Debe ingresar el nombre");
            setIsLoading(false);
            return;
        }
        if (form.id_empresa === 0) {
            toast.error("Debe seleccionar el usuario");
            setIsLoading(false);
            return;
        }

        dispatch(update_zones(token, id, form));
    };

    return (
        <div className="app-content content">
            <ToastContainer/>
            {isLoading && <LoadingSpinner/>}
            <div className="content-overlay"/>

            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-12 mb-2 mt-1">
                        <Breadcrumbs
                            title="Zona"
                            items={[
                                {label: "Centros de costos", link: "/zonas"},
                            ]}
                        />
                    </div>
                </div>
                <div className="content-body">
                    <div className="row">
                        <div className="col-12">
                            <form className="card" onSubmit={handleSubmit}>
                                <div className="card-header">
                                    <h4 className="card-title">Actualizar Zona</h4>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <Input
                                                label="Nombre"
                                                name="nombre"
                                                onChange={handleChange}
                                                value={form.nombre}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Usuario jefe zonal</label>

                                                <Select
                                                    label="Usuario"
                                                    name="id_usuario_jefe_zona"
                                                    options={users.map((item) => ({
                                                        name: item.nombres + ' ' + item.apellidos,
                                                        id: item.id,
                                                    }))}
                                                    onChange={handleChange}
                                                    value={form.id_usuario_jefe_zona}
                                                    select={form.usuario_jefe_zona}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <CheckBox label="Activo" name="activo" id="activo" onChange={handleChange}
                                                      state={form.activo}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer d-flex justify-content-end">
                                    <Link to="/zonas" className="btn btn-danger m-1">
                                        Cancelar
                                    </Link>
                                    <Button
                                        type="submit"
                                        text={
                                            <>
                                                <i className="bx bx-save"/> Guardar
                                            </>
                                        }
                                        theme="success"
                                        disabled={isLoading}
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UpdateZones;
