import React, {useEffect, useState} from "react";
import {TYPE_REPORT_EMPLOYEE_GENERATE, TYPE_REPORT_GENERATE} from "../config/ws-client";
import {save_notification} from '../redux/visits';
import {logout} from '../redux/users';
import {useDispatch, useSelector} from "react-redux";
// import {ToastContainer, toast} from 'react-toastify';
// import {getAllVisits} from "../redux/visits";
import {URLAPI} from '../config/index'

const Navbar = () => {
    const dispatch = useDispatch();
    // const {socket} = useContext(SocketContext);
    const [notification, setNotification] = useState([]);
    const _get_notification = useSelector((state) => state.visits_module._get_notification);
    const _session_data = useSelector((state) => state.users_module._session_data);
    const [open, setOpen] = useState(false);
    const [user_data, setUserData] = useState({
        nombres: "",
        apellidos: "",
        correo: "",
        grupo_permiso: {
            nombre: ""
        },
        centros_costos: []
    });
    const {token} = useSelector((state) => state.users_module.login.data) || null;

    // const audio = new Audio("/base/assets/audio/audio.mp3");

    useEffect(() => {
        if (_get_notification.data) {
            setNotification(_get_notification.data);
        }
    }, [_get_notification.data]);

    useEffect(() => {
        console.log(_session_data);
        if (_session_data.data) {
            setUserData(_session_data.data);
        }
    }, [_session_data]);


    //sleep function
    // const sleep = (ms) => {
    //     return new Promise(resolve => setTimeout(resolve, ms));
    // }


    useEffect(() => {
        if (open) {
            openMenu();
        } else {
            hideMenu();
        }
    }, [open])


    // const handleReceiveMessage = (message) => {
    //
    // };

    // const handleSendMessage = () => {
    //     //socket on connect
    //     socket.send(JSON.stringify({message: "Hello World"}));
    // };

    const handleClick = () => {
        dispatch(logout(token));
    }


    const download = (url, nombre) => {
        if (url && nombre) {
            var element = document.createElement('a');
            element.target = '_BLANK';
            document.body.appendChild(element);
            element.style = "display: none";

            element.href = url
            element.download = nombre

            element.click();
            document.body.removeChild(element)
        }

    }

    const openMenu = () => {
        document.body.classList.remove('menu-hide', 'menu-collapsed')
        document.body.classList.add('menu-open')
        document.body.classList.add('menu-expanded')

        if (document.body.classList.contains('vertical-overlay-menu')) {

            document.body.style = "overflow:hidden;"
        }


    }

    const hideMenu = () => {
        document.body.classList.remove('menu-open', 'menu-expanded')
        document.body.classList.add('menu-hide')

        document.body.style = "overflow:auto;"

    }


    const handleClickNotification = (data) => {

        if (data.type === TYPE_REPORT_GENERATE || data.type === TYPE_REPORT_EMPLOYEE_GENERATE) {
            if (data.result) {

                download(URLAPI + data.result.url, data.result.nombre)
            }
        }

        let filter = notification
        let final = []
        filter.forEach(x => {
            let d = x;
            if (data.id === x.id) {
                if (d.read === false) {
                    d.read = true;
                }
            }
            final.push(d)
        })
        dispatch(save_notification(final));

        return;

    }


    const handleClickDropNotification = () => {
        let filter = notification
        let final = []
        filter.forEach(x => {
            let data = x;
            if (data.show === true) {
                data.show = false;
            }
            final.push(data)
        })
        setNotification(final)
        dispatch(save_notification(final));

    }


    return (
        <>

            <nav className="header-navbar main-header-navbar navbar-expand-lg navbar navbar-with-menu fixed-top ">
                <div className="navbar-wrapper">
                    <div className="navbar-container content">
                        <div className="navbar-collapse" id="navbar-mobile">
                            <div className="mr-auto float-left bookmark-wrapper d-flex align-items-center">
                                <ul className="nav navbar-nav">
                                    <li className="nav-item mobile-menu d-xl-none mr-auto"
                                        onClick={() => setOpen(!open)}
                                    >
                                        {/*eslint-disable-next-line*/}
                                        <a
                                            className="nav-link nav-menu-main menu-toggle hidden-xs"
                                            href="#"
                                            onClick={() => setOpen(!open)}
                                        >
                                            <i className="ficon bx bx-menu"></i>
                                        </a>
                                    </li>
                                </ul>
                                <ul className="nav navbar-nav bookmark-icons">

                                </ul>
                            </div>
                            <ul className="nav navbar-nav float-right">

                                <li className="nav-item nav-search">
                                    {/*eslint-disable-next-line*/}
                                    <a className="nav-link nav-link-search">
                                        <i className="ficon bx bx-search"></i>
                                    </a>
                                    <div className="search-input">
                                        <div className="search-input-icon">
                                            <i className="bx bx-search primary"></i>
                                        </div>
                                        <input
                                            className="input"
                                            type="text"
                                            placeholder="Explore Frest..."
                                            tabIndex="-1"
                                            data-search="template-search"
                                        />
                                        <div className="search-input-close">
                                            <i className="bx bx-x"></i>
                                        </div>
                                        <ul className="search-list"></ul>
                                    </div>
                                </li>
                                <li className="dropdown dropdown-notification nav-item">
                                    {/*eslint-disable-next-line*/}
                                    <a
                                        className="nav-link nav-link-label"
                                        href="#"
                                        data-toggle="dropdown"
                                        onClick={handleClickDropNotification}
                                    >
                                        <i className="ficon bx bx-bell bx-tada bx-flip-horizontal"></i>
                                        {notification.filter(x => x.show === true).length > 0 && (
                                            <span className="badge badge-pill badge-danger badge-up">
                        {notification.filter(x => x.show === true).length}
                      </span>
                                        )}
                                    </a>
                                    <ul className="dropdown-menu dropdown-menu-media dropdown-menu-right">
                                        <li className="dropdown-menu-header">
                                            <div className="dropdown-header px-1 py-75 d-flex justify-content-between">
                        <span className="notification-title">
                          {notification.length} Nuevas notificaciones
                        </span>
                                                {/*<span className="text-bold-400 cursor-pointer">*/}
                                                {/*  Ver todas*/}
                                                {/*</span>*/}
                                            </div>
                                        </li>
                                        <li className="scrollable-container media-list read-notification cursor-pointer"
                                            style={{overflow: 'scroll', '-webkit-overflow-scrolling': 'touch'}}>

                                            {notification.length !== 0 ? notification.reverse().map((notification, index) => (
                                                <>
                                                    {/*eslint-disable-next-line*/}
                                                    <a className={`d-flex justify-content-between ${notification.read === true ? '' : 'read-notification'}`}
                                                       href="#" onClick={() => handleClickNotification(notification)}>
                                                        <div className="media d-flex align-items-center">
                                                            <div className="media-left pr-0">
                                                                <div className="avatar mr-1 m-0">
                                                                </div>
                                                            </div>
                                                            <div className="media-body">
                                                                <h6 className="media-heading">

                                                                    <span className="text-bold-500">{notification.title}</span>
                                                                    {notification.message}
                                                                </h6>
                                                                <small className="notification-text">
                                                                    {notification.date}
                                                                </small>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </>
                                            )) : null}
                                        </li>

                                        <li className="dropdown-menu-footer">
                                            {/*eslint-disable-next-line*/}
                                            <a
                                                className="dropdown-item p-50 text-primary justify-content-center"
                                                href="#"
                                            >
                                                Lee todas las notificaciones
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li className="dropdown dropdown-user nav-item">
                                    {/*eslint-disable-next-line*/}
                                    <a
                                        className="dropdown-toggle nav-link dropdown-user-link"
                                        href="#"
                                        data-toggle="dropdown"
                                    >
                                        <div className="user-nav d-sm-flex d-none">
                                            <span
                                                className="user-name">{user_data.nombres + " " + user_data.apellidos}</span>
                                            <span
                                                className="user-status text-muted">{user_data.grupo_permiso !== undefined ? user_data.grupo_permiso.nombre : ""}</span>

                                        </div>
                                        <span>
                      <i className="bx bx-user"></i>
                    </span>
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-right pb-0">

                                        <div className="dropdown-divider mb-0"></div>
                                        {/*eslint-disable-next-line*/}
                                        <a className="dropdown-item" href="#" onClick={handleClick}>
                                            <i className="bx bx-power-off mr-50"></i> Logout
                                        </a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>

        </>

    );
};

export default Navbar;
