import React, {useEffect, useState} from "react";
import Button from "../../../components/button";
import Breadcrumbs from "../../../components/breadcrumbs";
import Input from "../../../components/input";
import Select from "../../../components/select";
import CheckBox from "../../../components/check-box";
import {Link, useHistory, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
    clear_employee,
    updateEmployee,
    UPDATE_EMPLOYEE,
    getAllEmployees
} from "../../../redux/employees";
import {URLAPI} from "../../../config/index";
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoadingSpinner from "../../../components/loading-spinner";

const UpdateEmployee = () => {
    const dispatch = useDispatch();
    const {id} = useParams();
    /* A state variable that is used to store the form data. */
    const [form, setForm] = useState({
        rut: "",
        nombres: "",
        apellidos: "",
        id_cargo: 0,
        cargo: "",
        id_empresa: 0,
        id_centro_costo: 0,
        empresa: "",
        centro_costo: "",
        imagen: "",
        correo: "",
        telefono: "",
        fecha_ingreso: "",
        activo: true
    });

    const [isLoading, setIsLoading] = useState(false);

    let history = useHistory();
    const _companies = useSelector(
        (state) => state.company_module._get_all_companies
    );

    const _cost_center = useSelector(
        (state) => state.cost_center_module._get_all_cost_centers
    );

    const _update_employee = useSelector(
        (state) => state.employees_module._update_employee
    );

    const _get_all_employees = useSelector(
        (state) => state.employees_module._get_all_employees
    );

    const _jobs = useSelector((state) => state.jobs_module._get_all_jobs);

    const {token} = useSelector((state) => state.users_module.login.data) || null;

    const [companies, setCompanies] = useState([]);
    const [cost_centers, setCostCenters] = useState([]);

    useEffect(() => {
        if (_get_all_employees.data) {
            // eslint-disable-next-line
            _get_all_employees.data.datos.map((item) => {
                if (item.id === parseInt(id)) {
                    setForm(item);
                }
            });
        }
    }, [_get_all_employees, id]);

    useEffect(() => {
        if (_companies.data.datos) {
            setCompanies(_companies.data.datos);
        }
    }, [_companies]);

    useEffect(() => {
        if (_cost_center.data.datos) {
            setCostCenters(_cost_center.data.datos);
        }
    }, [_cost_center]);

    /* A state variable that is used to store the form data. */
    useEffect(() => {
        if (_update_employee.status === 200) {
            toast.success("El empleado ha sido creado correctamente")
            dispatch(clear_employee(UPDATE_EMPLOYEE));
            history.push("/empleados");
            dispatch(getAllEmployees(token));
            setIsLoading(false);
        } else {
            if (_update_employee.status !== 0) {
                toast.error(_update_employee.message);
                setIsLoading(false);
            }
        }
    }, [_update_employee, token, dispatch, history]);

    useEffect(() => {
    }, []);

    const [jobs, setJobs] = useState([]);

    useEffect(() => {
        if (_jobs.data) {
            let cargos = _jobs.data.datos.filter(x => x.activo === true)
            setJobs(cargos);
        }
    }, [_jobs]);

    const handleChange = (e) => {
        if (e.target.name === 'activo') {
            setForm({
                ...form,
                [e.target.name]: e.target.checked,
            });
        } else {
            setForm({
                ...form,
                [e.target.name]: e.target.value,
            });
        }

    };

    const [imagePreview, setImagePreview] = useState(null);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        let url = URL.createObjectURL(file);
        setImagePreview(url);
        setForm({
            ...form,
            imagen: e.target.files[0],
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);
        //validate fields
        if (form.rut === "") {
            toast.error("Debe ingresar el rut");
            setIsLoading(false);
            return;
        }
        if (form.nombre === "") {
            toast.error("Debe ingresar el nombre");
            setIsLoading(false);
            return;
        }
        if (form.apellidos === "") {
            toast.error("Debe ingresar el apellido");
            setIsLoading(false);
            return;
        }
        if (form.id_cargo === 0) {
            toast.error("Debe seleccionar el cargo");
            setIsLoading(false);
            return;
        }
        if (form.id_empresa === 0) {
            toast.error("Debe seleccionar la empresa");
            setIsLoading(false);
            return;
        }

        if (form.id_centro_costo === 0) {
            toast.error("Debe seleccionar el centro de costo");
            setIsLoading(false);
            return;
        }
        if (form.imagen === "") {
            toast.error("La imagen es requerida");
            setIsLoading(false);
            return;
        }
        dispatch(updateEmployee(token, id, form));
    };

    return (
        <div className="app-content content">
            <ToastContainer/>
            {isLoading && <LoadingSpinner/>}
            <div className="content-overlay"/>
            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-12 mb-2 mt-1">
                        <Breadcrumbs
                            title="Empleados"
                            items={[
                                {label: "Empleados", link: "/empleados"},
                            ]}
                        />
                    </div>
                </div>
                <div className="content-body">
                    <div className="row">
                        <div className="col-12">
                            <form className="card" onSubmit={handleSubmit}>
                                <div className="card-header">
                                    <h4 className="card-title">Actualizar Empleado</h4>
                                </div>
                                <div className="card-body">
                                    <div className=" row">
                                        <div className="col-md-4">
                                            <Input
                                                label="Rut"
                                                name="rut"
                                                onChange={handleChange}
                                                value={form.rut}
                                                readonly="readonly"
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <Input
                                                label="Nombre"
                                                name="nombres"
                                                onChange={handleChange}
                                                value={form.nombres}
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <Input
                                                label="Apellidos"
                                                name="apellidos"
                                                onChange={handleChange}
                                                value={form.apellidos}
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <Input
                                                label="Correo"
                                                name="correo"
                                                onChange={handleChange}
                                                value={form.correo}
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <Input
                                                label="Telefono"
                                                name="telefono"
                                                onChange={handleChange}
                                                value={form.telefono}
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <Input
                                                type="date"
                                                label="Fecha Ingreso"
                                                name="fecha_ingreso"
                                                onChange={handleChange}
                                                value={form.fecha_ingreso}
                                            />
                                        </div>
                                    </div>
                                    <div className=" row">
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>Cargo</label>

                                                <Select
                                                    label="Cargo"
                                                    name="id_cargo"
                                                    value={form.id_cargo}
                                                    select={form.cargo}
                                                    options={jobs.map((job) => ({
                                                        name: job.nombre,
                                                        id: job.id,
                                                    }))}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>Empresa</label>

                                                <Select
                                                    label="Empresa"
                                                    name="id_empresa"
                                                    value={form.id_empresa}
                                                    select={form.empresa}
                                                    options={companies.map((company) => ({
                                                        name: company.nombre,
                                                        id: company.id,
                                                    }))}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>Centro de costo</label>
                                                <Select
                                                    label="Centro de costo"
                                                    name="id_centro_costo"
                                                    value={form.id_centro_costo}
                                                    select={form.centro_costo}
                                                    options={cost_centers.map((item) => ({
                                                        name: item.nombre,
                                                        id: item.id,
                                                    }))}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <Input
                                                label="Imagen"
                                                name="imagen"
                                                type="file"
                                                accept="image/*"
                                                onChange={handleFileChange}
                                            />
                                        </div>
                                        <div className="col-md-6 text-center">
                                            <img
                                                src={
                                                    imagePreview == null
                                                        ? `${URLAPI}${form.foto}`
                                                        : imagePreview
                                                }
                                                alt="imagen"
                                                width={60}
                                            />
                                        </div>
                                        <div className="col-6">
                                            <CheckBox label="Activo" name="activo" id="activo" onChange={handleChange}
                                                      state={form.activo}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer d-flex justify-content-end">
                                    <Link to="/empleados" className="btn btn-danger m-1">
                                        Cancelar
                                    </Link>
                                    <Button
                                        text="Guardar"
                                        theme="success"
                                        type="submit"
                                        classes="m-1"
                                        onClick={handleSubmit}
                                        disabled={isLoading}
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UpdateEmployee;
