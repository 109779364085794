import React, {useEffect, useState} from "react";
import DataTable from "../../../components/data-table";
import Breadcrumbs from "../../../components/breadcrumbs";
import {useSelector} from "react-redux";
import {Link, useHistory} from "react-router-dom";

const Users = (props) => {
    let history = useHistory();
    const users = useSelector(
        (state) => state.users_module._get_all_users
    );
    const _get_all_cost_centers = useSelector(
        (state) => state.cost_center_module._get_all_cost_centers
    );

    const [cost_centers, setCostCenters] = useState([])

    useEffect(() => {
        if (_get_all_cost_centers) {
            setCostCenters(_get_all_cost_centers.data.datos)
        }
    }, [_get_all_cost_centers])


    const [data, setData] = useState([]);
    useEffect(() => {
        if (users.data) {
            let usuarios = users.data.filter(x => x.centros_costos.length === cost_centers.length)

            let newusuarios = usuarios.map((item) => {
                return {...item, centros_costos: [{id: 0, codigo: "ALL", nombre: "TODOS"}]}

            });
            let others = users.data.filter(x => x.centros_costos.length !== cost_centers.length)
            setData([...newusuarios, ...others]);
        }
    }, [users, cost_centers]);


    const [permisos, setPermisos] = useState([]);
    const [actions, setActions] = useState([]);


    useEffect(() => {
        if (props) {
            const array = Object.values(props);
            setPermisos(array);

        }
    }, [props]);


    useEffect(() => {
        let act = []
        if (permisos) {
            if (permisos.filter(x => x.codigo === 'change_user').length > 0) {
                act.push({
                    name: "edit",
                    label: "Edit",
                    icon: "bx bx-edit",
                    color: "primary",
                    onClick: (item) => {
                        history.push(`/usuarios/editar/${item.id}`);
                    },
                })

            }
        }
        setActions(act);
    }, [permisos, history]);
    return (
        <div className="app-content content">
            <div className="content-overlay"/>

            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-12 mb-2 mt-1">
                        <Breadcrumbs
                            title="Usuarios"
                            items={[{label: "Usuarios", link: "/usuarios"}]}
                        />
                    </div>
                </div>
                <div className="content-body">
                    <div className="card">
                        <div className="card-content">
                            <div className="card-body">
                                {
                                    permisos.filter(x => x.codigo === 'add_user').length > 0 ?
                                        <Link
                                            to="/usuarios/crear"
                                            className="btn btn-success"
                                        >
                                            Crear
                                        </Link> : null
                                }

                                <div className="table-responsive">

                                    <DataTable
                                        dataTable={data}
                                        columns={[
                                            {
                                                name: "usuario",
                                                label: "Usuario",
                                                type: "text",
                                            },
                                            {
                                                name: "nombres",
                                                label: "Nombres",
                                                type: "text",
                                            },
                                            {
                                                name: "apellidos",
                                                label: "Apellidos",
                                                type: "text",
                                            },
                                            {
                                                name: "activo",
                                                label: "Activo",
                                                type: "boolean",
                                            },
                                            {
                                                name: "n_centros_costos",
                                                label: "Centros de costos",
                                                type: "text",
                                            },

                                        ]}
                                        actions={actions}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Users;
