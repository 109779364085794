import {createStore, combineReducers, compose, applyMiddleware} from "redux";
import {loadState} from "./state";
import thunk from "redux-thunk";
import {persistStore, persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";
import {users_module} from "./users";
import {company_module} from "./company";
import {cost_center_module} from "./cost_center";
import {employees_module} from "./employees";
import {supplier_module} from "./supplier";
import {visitant_module} from "./visitant";
import {jobs_module} from "./jobs";
import {visits_module} from "./visits";
import {base_module} from './base';
import {help_module} from './help';
import {email_module} from './email_zones';
import {zone_module} from './zones';

const rootReducer = combineReducers({
    users_module: users_module,
    email_module: email_module,
    company_module: company_module,
    cost_center_module: cost_center_module,
    employees_module: employees_module,
    supplier_module: supplier_module,
    visitant_module: visitant_module,
    jobs_module: jobs_module,
    visits_module: visits_module,
    base_module: base_module,
    help_module: help_module,
    zone_module: zone_module,
});

const persistConfig = {
    key: "root",
    storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const generateStore = () => {
    const initialData = loadState();

    const store = createStore(
        persistedReducer,
        initialData,
        composeEnhancers(applyMiddleware(thunk))
    );

    const persistor = persistStore(store, ["auth"]);

    return {store, persistor};
};
