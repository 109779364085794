import React from "react";

const Search = ({ placeholder, id, name, onChange, onKeyPress }) => {
  return (
    <fieldset className="form-group position-relative has-icon-left m-0">
      <input
        type="text"
        className="form-control"
        id={id}
        name={name}
        onChange={onChange}
        onKeyPress={onKeyPress}
        placeholder={placeholder}
      />

      <div className="form-control-position">
        <i className="bx bx-search"></i>
      </div>
    </fieldset>
  );
};

export default Search;
