import React, {useEffect, useState} from "react";
import DataTable from "../../../components/data-table";
import Breadcrumbs from "../../../components/breadcrumbs";
import {useSelector} from "react-redux";
import {Link, useHistory} from "react-router-dom";

/** @module  Pages/CostCenter */


const Costs_Centers = (props) => {
    let history = useHistory();
    const costs_centers = useSelector(
        (state) => state.cost_center_module._get_all_cost_centers
    );
    const [data, setData] = useState([]);
    useEffect(() => {

        if (costs_centers.data.datos) {
            setData(costs_centers.data.datos);
        }
    }, [costs_centers]);
    const [permisos, setPermisos] = useState([]);
    const [actions, setActions] = useState([]);


    useEffect(() => {
        if (props) {
            const array = Object.values(props);
            setPermisos(array);

        }
    }, [props]);


    useEffect(() => {
        let act = []
        if (permisos) {
            if (permisos.filter(x => x.codigo === 'change_costcenter').length > 0) {
                act.push({
                    name: "edit",
                    label: "Edit",
                    icon: "bx bx-edit",
                    color: "primary",
                    onClick: (item) => {
                        history.push(`/centros-de-costos/editar/${item.id}`);
                    },
                })
            }
        }
        setActions(act);
    }, [permisos, history]);


    return (
        <div className="app-content content">
            <div className="content-overlay"/>

            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-12 mb-2 mt-1">
                        <Breadcrumbs
                            title="Centros de Costo"
                            items={[{label: "Centros de costos", link: "/centros-de-costos"}]}
                        />
                    </div>
                </div>
                <div className="content-body">
                    <div className="card">
                        <div className="card-content">
                            <div className="card-body">
                                {
                                    permisos.filter(x => x.codigo === 'add_costcenter').length > 0 ?
                                        <Link
                                            to="/centros-de-costos/crear"
                                            className="btn btn-success"
                                        >
                                            Crear

                                        </Link> : null
                                }

                                <div className="table-responsive">
                                    <DataTable
                                        dataTable={data}
                                        columns={[
                                            {
                                                name: "codigo",
                                                label: "Codigo",
                                                type: "text",
                                            },
                                            {
                                                name: "nombre",
                                                label: "Nombre",
                                                type: "text",
                                            },
                                            {
                                                name: "empresa",
                                                label: "Empresa",
                                                type: "text",
                                            },
                                            {
                                                name: "zona",
                                                label: "Zona",
                                                type: "text",
                                            },
                                            {
                                                name: "fecha_creacion",
                                                label: "Fecha Creacion",
                                                type: "text",
                                            },
                                            {
                                                name: "activo",
                                                label: "Activo",
                                                type: "boolean",
                                            }
                                        ]}
                                        actions={actions}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Costs_Centers;
