import React, {useEffect, useState} from "react";
import Button from "../../../components/button";
import Breadcrumbs from "../../../components/breadcrumbs";
import Input from "../../../components/input";
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useDispatch, useSelector} from "react-redux";
import {Link, useHistory, useParams} from "react-router-dom";
import {
    update_companies,
    clear_status_companie,
    get_all_companies,
    UPDATE_COMPANIES_SUCCESS,
} from "../../../redux/company";
import LoadingSpinner from "../../../components/loading-spinner";

/** @module  Pages/Company/UpdateCompany */


const UpdateCompany = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const {id} = useParams();
    const {_update_companies, _get_all_companies} = useSelector(
        (state) => state.company_module
    );
    const {token} = useSelector((state) => state.users_module.login.data) || null;

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (_get_all_companies) {
            if (_get_all_companies.status === 200) {
                if (JSON.stringify(_get_all_companies.data) !== "{}") {
                    // eslint-disable-next-line
                    _get_all_companies.data.datos.map((company) => {
                        if (company.id === parseInt(id)) {
                            setForm(company);
                        }
                    });
                }
            }
        }
    }, [_get_all_companies, id]);

    useEffect(() => {
        if (_update_companies) {
            if (_update_companies.status === 200) {
                toast.success("La empresa ha sido actualizada correctamente");
                dispatch(clear_status_companie(UPDATE_COMPANIES_SUCCESS));
                dispatch(get_all_companies(token));
                history.push("/empresas");
                setIsLoading(false);
            } else if (_update_companies.status !== 0) {
                if (_update_companies.message) {
                    toast.error(_update_companies.message);
                    dispatch(clear_status_companie(UPDATE_COMPANIES_SUCCESS));
                    setIsLoading(false);
                }
            }
        }
    }, [_update_companies, dispatch, history, token]);

    const [form, setForm] = useState({
        rut: "",
        nombre: "",
    });
    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);
        //validate fields
        if (form.rut === "") {
            toast.error("Debe ingresar el rut");
            setIsLoading(false);
            return;
        }
        if (form.nombre === "") {
            toast.error("Debe ingresar el nombre");
            setIsLoading(false);
            return;
        }
        dispatch(update_companies(token, id, form));
    };

    return (
        <div className="app-content content">
            <ToastContainer/>
            {isLoading && <LoadingSpinner/>}
            <div className="content-overlay"/>

            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-12 mb-2 mt-1">
                        <Breadcrumbs
                            title="Empresas"
                            items={[
                                {label: "Empresas", link: "/empresas"},
                            ]}
                        />
                    </div>
                </div>
                <div className="content-body">
                    <div className="row">
                        <div className="col-12">
                            <form className="card" onSubmit={handleSubmit}>
                                <div className="card-header">
                                    <h4 className="card-title">Actualizar Empresa</h4>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <Input
                                                id="rut"
                                                name="rut"
                                                label="RUT"
                                                value={form.rut}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <Input
                                                id="nombre"
                                                name="nombre"
                                                label="Nombre"
                                                value={form.nombre}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer d-flex justify-content-end">
                                    <Link to="/empresas" className="btn btn-danger m-1">
                                        Cancelar
                                    </Link>

                                    <Button
                                        type="submit"
                                        className="btn btn-success"
                                        onClick={() => {
                                        }}
                                        text="Guardar"
                                        theme="success"
                                        disabled={isLoading}
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UpdateCompany;
