export const URLAPI = "https://pruebas.back.ingresocl.innode.pro";


export const WEBSOCKET = 'wss://pruebas.back.ingresocl.innode.pro';

export const LOGIN_PATH = "/token/";
export const TASK_PATH = "/employee/task/"


export const GET_SETTINGS_ALL_PATH = "/settings/"

export const COMPANY_PATH = "/company/list/";
export const COMPANY_UPDATE_PATH = "/company/update/";
export const COMPANY_CREATE_PATH = "/company/create/";

export const COST_CENTER_PATH = "/cost-center/list/";
export const COST_CENTER_UPDATE_PATH = "/cost-center/update/";
export const COST_CENTER_CREATE_PATH = "/cost-center/create/";
export const ZONE_PATH = "/zone/list/";
export const ZONE_UPDATE_PATH = "/zone/update/";
export const ZONE_CREATE_PATH = "/zone/create/";
export const EMPLOYEE_PATH = "/employee/list/";
export const VISITORS_PATH = "/employee/visitors-list/";
export const EMPLOYEE_UPDATE_PATH = "/employee/update/";
export const EMPLOYEE_CREATE_PATH = "/employee/create/";
export const EMPLOYEE_CREATE_LINK_PATH  = "/employee/photo-upload-link/create/";
export const EMPLOYEE_UPDATEPHOTO_PATH = "/employee/updatephoto/";
export const EMPLOYEE_IMPORT_PATH = "/employee/import/";
export const EMPLOYEE_REPORT_PATH = "/employee/report/";
export const EMPLOYEE_REPORT_CHECK_PATH = "/employee/report/check/";
export const EMPLOYEE_VALIDATE_LINK_PATH = "/employee/validate-link-photo/";


export const JOBS_PATH = "/job/list/";
export const JOBS_UPDATE_PATH = "/job/update/";
export const JOBS_CREATE_PATH = "/job/create/";

export const SUPPLIER_PATH = "/supplier/list/";
export const SUPPLIER_UPDATE_PATH = "/supplier/update/";
export const SUPPLIER_DELETE_PATH = "/supplier/delete/";
export const SUPPLIER_CREATE_PATH = "/supplier/create/";

export const USERS_PATH = "/user/list/";
export const USERS_UPDATE_PATH = "/user/update/";
export const USERS_CREATE_PATH = "/user/create/";
export const USER_GROUPS_PATH = "/user/group-list/";
export const USER_GET_PATH = "/user/get/";
export const USER_GET_ALL_PERMISSIONS_PATH = '/user/permission-list/';
export const USER_CREATE_GROUP_PATH = '/user/group-create/';
export const USER_UPDATE_GROUP_PATH = '/user/group-update/';
export const USER_ACTIVATE_PATH = '/user/activate/';
export const USER_LOGOUT_PATH = '/user/logout/';
export const USER_PASSWORD_RESET_PATH = '/user/password/reset/';
export const USER_PASSWORD_RESET_CONFIRM_PATH = '/user/password/reset/confirm/';

export const VISITANT_PATH = "/visitant/list/";
export const VISITANT_UPDATE_PATH = "/visitant/update/";
export const VISITANT_DELETE_PATH = "/visitant/delete/";
export const VISITANT_CREATE_PATH = "/visitant/create/";


export const VISITS_PATH = "/visit/list/";
export const VISITS_UPDATE_PATH = "/visit/update/";
export const SEARCH_VISITANT_PATH = '/employee/employees-supplier-visitant-list/';
export const VISITS_CREATE_PATH = "/visit/create/";
export const VISITS_REPORT_PATH = "/visit/report/"
export const VISITS_REPORT_CHECK_PATH = "/visit/report/check/"

export const EMAIL_CREATE_PATH = "/zone/email/create/";
export const EMAIL_UPDATE_PATH = "/zone/email/update/";
export const EMAIL_DELETE_PATH = "/zone/email/delete/";

const BASE_CREADO = "CREADO";
const BASE_EN_ESPERA = "EN ESPERA";
const BASE_APROBADO = "APROBADO";
const BASE_ANULADO = "ANULADO";
const BASE_CADUCADO = "CADUCADO";

export const get_status = (status)  => {
    switch (status) {
        case BASE_CREADO:
            return 'primary';
        case BASE_EN_ESPERA:
            return 'warning';
        case BASE_APROBADO:
            return 'success';
        case BASE_ANULADO:
            return 'danger';
        case BASE_CADUCADO:
            return 'dark';
        default:
            return 'primary';
    }
}


export const TYPE_EMPLOYEE = "EMPLOYEE";
export const TYPE_VISITANT = "VISITANT";
export const TYPE_ALL = "ALL";
export const TYPE_COMPANY = "COMPANY";

export const APP_NAME = "Sistema de Ingreso";


export const URL_FILE_UPLOAD_EMPLOYEE = "URL_FILE_UPLOAD_EMPLOYEE";

