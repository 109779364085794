import React, {useState, useEffect} from "react";
import route from "../pages/routes";
import {useSelector} from "react-redux";

import {Link} from "react-router-dom";

const Sidebar = () => {

    const [permisos, setPermissions] = useState([]);
    const _session_data = useSelector((state) => state.users_module._session_data);


    useEffect(() => {

        if (_session_data.data) {
            if (_session_data.data.grupo_permiso) {
                if (_session_data.data.grupo_permiso.permisos) {
                    setPermissions(_session_data.data.grupo_permiso.permisos);
                }
            }
        }

    }, [_session_data]);


    const hideMenu = () => {
        document.body.classList.remove('menu-open', 'menu-expanded')
        document.body.classList.add('menu-hide')

        document.body.style = "overflow:auto;"

    }

    return (
        <div
            className="main-menu menu-fixed menu-dark menu-accordion menu-shadow"
            data-scroll-to-active="true"
        >
            <div className="navbar-header">
                <ul className="nav navbar-nav flex-row">
                    <li className="nav-item mr-auto">
                        <a
                            className="navbar-brand"
                            href="/"
                        >
                            <div className="brand-logo">
                                <img
                                    className="logo"
                                    src="/Logo.png"
                                    alt="logo"
                                />
                            </div>
                        </a>
                    </li>
                    <li className="nav-item nav-toggle">
                        {/*eslint-disable-next-line*/}
                        <a
                            href="#"
                            className="nav-link modern-nav-toggle pr-0"
                            data-toggle="collapse"
                            onClick={() => hideMenu()}
                        >
                            <i className="bx bx-x d-block d-xl-none font-medium-4 primary"></i>
                            <i
                                className="toggle-icon bx bx-disc font-medium-4 d-none d-xl-block primary"
                                data-ticon="bx-disc"
                            ></i>
                        </a>
                    </li>
                </ul>
            </div>

            <div className="main-menu-content">
                <ul
                    className="navigation navigation-main"
                    id="main-menu-navigation"
                    data-menu="menu-navigation"
                    data-icon-style="lines"
                >
                    {/*eslint-disable-next-line*/}
                    {route.map((item, index) => {
                        if (item.show_in_menu
                            && permisos.filter(x => item.meta.permission_required.includes(x.codigo)).length > 0

                        ) {
                            return (
                                <li className=" nav-item">
                                    <Link to={item.path}>
                                        {" "}
                                        <i className={item.icon}></i>
                                        <span className="menu-title" data-i18n="Email">
                      {item.meta.title}
                    </span>
                                    </Link>
                                </li>
                            );
                        } else {
                            if (item.show_in_menu && !item.meta.requiresAuth) {
                                return (
                                    <li className=" nav-item">
                                        <Link to={item.path}>
                                            {" "}
                                            <i className={item.icon}></i>
                                            <span className="menu-title" data-i18n="Email">
                        {item.meta.title}
                      </span>
                                        </Link>
                                    </li>
                                );
                            }
                        }


                    })}
                    <li className=" nav-item">
                        {/*eslint-disable-next-line*/}
                        <a href="https://ilissolutionscl.notion.site/Manual-de-usuario-3fd8fc8d4d3245c697e4ac93fbe33192"
                           target="_BLANK">
                            {" "}
                            <i className="bx bxs-info-circle"></i>
                            <span className="menu-title" data-i18n="Email">
                Ayuda
              </span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    );
};
export default Sidebar;
