import React, {useEffect, useState} from "react";
import DataTable from "../../../components/data-table";
import Breadcrumbs from "../../../components/breadcrumbs";
import {useSelector} from "react-redux";
import {Link, useHistory} from "react-router-dom";

/** @module  Pages/CostCenter */


const Zones = (props) => {
    let history = useHistory();
    const zones = useSelector(
        (state) => state.zone_module._get_all_zones
    );
    const [data, setData] = useState([]);
    useEffect(() => {

        if (zones.data.datos) {
            setData(zones.data.datos);
        }
    }, [zones]);
    const [permisos, setPermisos] = useState([]);
    const [actions, setActions] = useState([]);


    useEffect(() => {
        if (props) {
            const array = Object.values(props);
            setPermisos(array);

        }
    }, [props]);


    useEffect(() => {
        let act = []
        if (permisos) {
            if (permisos.filter(x => x.codigo === 'change_zones').length > 0) {
                act.push({
                    name: "edit",
                    label: "Edit",
                    icon: "bx bx-edit",
                    color: "primary",
                    onClick: (item) => {
                        history.push(`/zona/editar/${item.id}`);
                    },
                })
            }
        }
        setActions(act);
    }, [permisos, history]);


    return (
        <div className="app-content content">
            <div className="content-overlay"/>

            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-12 mb-2 mt-1">
                        <Breadcrumbs
                            title="Zonas"
                            items={[{label: "Zonas", link: "/zonas"}]}
                        />
                    </div>
                </div>
                <div className="content-body">
                    <div className="card">
                        <div className="card-content">
                            <div className="card-body">
                                {
                                    permisos.filter(x => x.codigo === 'add_zones').length > 0 ?
                                        <Link
                                            to="/zona/crear"
                                            className="btn btn-success"
                                        >
                                            Crear

                                        </Link> : null
                                }

                                <div className="table-responsive">
                                    <DataTable
                                        dataTable={data}
                                        columns={[
                                            {
                                                name: "nombre",
                                                label: "Nombre",
                                                type: "text",
                                            },
                                            {
                                                name: "usuario_jefe_zonal",
                                                label: "Usurio jefe zonal",
                                                type: "object",
                                                field_show: "nombres"
                                            },
                                            {
                                                name: "fecha_creacion",
                                                label: "Fecha Creacion",
                                                type: "text",
                                            },
                                            {
                                                name: "activo",
                                                label: "Activo",
                                                type: "boolean",
                                            }
                                        ]}
                                        actions={actions}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Zones;
