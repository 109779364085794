import React, {useEffect, useState} from "react";
import Button from "../../../components/button";
import Breadcrumbs from "../../../components/breadcrumbs";
import Input from "../../../components/input";
import Select from "../../../components/select";
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useDispatch, useSelector} from "react-redux";
import {Link, useHistory} from "react-router-dom";
import {
    create_cost_centers,
    CREATE_COST_CENTERS_SUCCESS,
    get_all_cost_centers,
    clear_cost_center
} from '../../../redux/cost_center';
import LoadingSpinner from "../../../components/loading-spinner";

/** @module  Pages/CostCenter/CreateCostCenter */


const CreateCostCenter = () => {
    let history = useHistory();
    const _companies = useSelector(
        (state) => state.company_module._get_all_companies
    );
    const {token} = useSelector((state) => state.users_module.login.data) || null;

    const _create_cost_centers = useSelector(
        (state) => state.cost_center_module._create_cost_centers
    );

    const dispatch = useDispatch();
    const [form, setForm] = useState({
        codigo: "",
        nombre: "",
        id_empresa: 0,
    });
    const [companies, setCompanies] = useState([]);

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (_companies.data.datos) {
            setCompanies(_companies.data.datos);
        }
    }, [_companies]);

    useEffect(() => {
        if (_create_cost_centers) {
            if (_create_cost_centers.status === 201) {
                toast.success("El centro de costo ha sido creado correctamente");
                dispatch(clear_cost_center(CREATE_COST_CENTERS_SUCCESS));
                dispatch(get_all_cost_centers(token));
                setIsLoading(false);
                history.push("/centros-de-costos");
            } else if (_create_cost_centers.status !== 0) {
                if (_create_cost_centers.message) {
                    toast.error(_create_cost_centers.message)
                    dispatch(clear_cost_center(CREATE_COST_CENTERS_SUCCESS));
                    setIsLoading(false);
                }
            }
        }
    }, [_create_cost_centers, dispatch, history, token]);

    const handleChange = (e) => {
        if (e.target.name === "id_empresa") {
            //parse to int
            setForm({
                ...form,
                [e.target.name]: parseInt(e.target.value),
            });
        } else {
            setForm({
                ...form,
                [e.target.name]: e.target.value,
            });
        }

    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);
        //validate data
        if (form.codigo === "") {
            toast.error("Debe ingresar el código");
            setIsLoading(false);
            return;
        }
        if (form.nombre === "") {
            toast.error("Debe ingresar el nombre");
            setIsLoading(false);
            return;
        }
        if (form.id_empresa === 0) {
            toast.error("Debe seleccionar la empresa");
            setIsLoading(false);
            return;
        }
        dispatch(create_cost_centers(token, form));
    }

    return (
        <div className="app-content content">
            <ToastContainer/>
            {isLoading && <LoadingSpinner/>}
            <div className="content-overlay"/>

            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-12 mb-2 mt-1">
                        <Breadcrumbs
                            title="Centros de Costo"
                            items={[
                                {label: "Centros de costos", link: "/centros-de-costos"},
                            ]}
                        />
                    </div>
                </div>
                <div className="content-body">
                    <div className="row">
                        <div className="col-12">
                            <form className="card" onSubmit={handleSubmit}>
                                <div className="card-header">
                                    <h4 className="card-title">Crear Centro de Costo</h4>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <Input
                                                label="Codigo"
                                                name="codigo"
                                                onChange={handleChange}
                                                value={form.codigo}
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <Input
                                                label="Nombre"
                                                name="nombre"
                                                onChange={handleChange}
                                                value={form.nombre}
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>Empresa</label>

                                                <Select
                                                    label="Empresa"
                                                    name="id_empresa"
                                                    options={companies.map((company) => ({
                                                        name: company.nombre,
                                                        id: company.id,
                                                    }))}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer d-flex justify-content-end">
                                    <Link to="/centros-de-costos" className="btn btn-danger m-1">
                                        Cancelar
                                    </Link>
                                    <Button
                                        type="submit"
                                        text={
                                            <>
                                                <i className="bx bx-save"/> Guardar
                                            </>
                                        }
                                        theme="success"
                                        disabled={isLoading}
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateCostCenter;
