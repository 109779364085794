import React, {useEffect, useState} from "react";
import DataTable from "../../../components/data-table";
import Breadcrumbs from "../../../components/breadcrumbs";
import {useSelector} from "react-redux";
import Modal from "../../../components/modal";
import {URLAPI} from "../../../config";
// import {useHistory} from "react-router-dom";
// import {createLink, CREATE_LINK, clear_employee, searchEmployees, get_report_employee, GET_REPORT_EMPLOYEE} from '../../../redux/employees';
// import {TYPE_EMPLOYEE} from '../../../config/index'
// import Button from "../../../components/button";
// import LoadingSpinner from "../../../components/loading-spinner";

const Visitors = (props) => {
    // let dispatch = useDispatch();
    // let history = useHistory();
    
    const _get_all_visitors = useSelector(
        (state) => state.employees_module._get_all_visitors
    );
    
    // const {token} = useSelector((state) => state.users_module.login.data) || null;

    const [data, setData] = useState([]);
    const [modal, setModal] = useState(false);
    const [item, setItem] = useState({
        'id': '',
        'rut': '',
        'nombres': '',
        'foto': '',
        'correo': '',
        'telefono': '',
        'cargo': '',
        'empresa': '',
    });

    useEffect(() => {
        console.log('_get_all_visitors')
        console.log(_get_all_visitors.data)
        if (_get_all_visitors.data) {
            // eslint-disable-next-line
            // let empleados = _get_all_visitors.data.datos.filter((item) => {
            //     if (item.activo) {
            //         return item;
            //     }
            // })
            setData(_get_all_visitors.data);
        }
    }, [_get_all_visitors]);

    // const [isLoading, setIsLoading] = useState(false);


    return (
        <div className="app-content content">
            <div className="content-overlay"/>
            {/*{isLoading && <LoadingSpinner/>}*/}

            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-12 mb-2 mt-1">
                        <Breadcrumbs
                            title="Visitantes"
                            items={[{label: "Visitantes", link: "/visitantes"}]}
                        />
                    </div>
                </div>
                <div className="content-body">
                    <div className="card">
                        <div className="card-content">
                            <div className="card-body">
                                <div className="row">
                                    {/*<div className="col-md-4 col-12">*/}
                                    {/*    <div className="form-group">*/}
                                    {/*        <label>Filtrar</label>*/}
                                    {/*        <select onChange={handleChange} className="form-control">*/}
                                    {/*            <option value="1" selected>Activos</option>*/}
                                    {/*            <option value="0">Inactivos</option>*/}
                                    {/*        </select>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                </div>

                                <div className="table-responsive">
                                    <DataTable
                                        dataTable={data}
                                        columns={[
                                            {
                                                name: "rut",
                                                label: "Rut",
                                                type: "text",
                                            },
                                            {
                                                name: "nombres",
                                                label: "Nombres y Apellidos",
                                                type: "text",
                                            },
                                            {
                                                name: "tipo",
                                                label: "Tipo",
                                                type: "text",
                                            },
                                        ]}
                                        actions={[{
                                            name: "view",
                                            label: "Ver",
                                            icon: "bx bx-show",
                                            color: "primary",
                                            onClick: (item) => {
                                                setItem(item);
                                                setModal(true);
                                            },
                                        }]}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal className="modal-main div-modal" show={modal}>
                <div className="modal-header">
                    <h4 className="text-bold-400">{item.nombres}</h4>
                </div>
                <div className="modal-body container">
                    <div className="row">
                        <div className="col-md-6">
                            {
                                item.foto ?
                                    <img
                                        src={URLAPI + item.foto}
                                        width="80%"
                                        height="80%"
                                        alt="avatar"

                                    />
                                :
                                    <img
                                        src={window.location.origin + "/base/app-assets/images/profile/no_perfil.png"}
                                        width="80%"
                                        height="80%"
                                        alt="avatar"
                                    />
                            }
                        </div>
                        <div className="col-md-6">
                            <p>{item.rut}</p>
                            <p>{item.cargo}</p>
                            {(item.empresa) &&
                                <p>Empresa: {item.empresa}</p>
                            }
                            {(item.cargo) &&
                                <p>Cargo: {item.cargo}</p>
                            }
                        </div>

                    </div>
                    <div className="mt-2 col-12 text-center">
                        <button type="button" onClick={() => {
                            setModal(false);
                        }} className={`btn btn-success button_div`}>Aceptar
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default Visitors;
